<template>
  <div class="mt-12">
     <div class="card bg-white rounded-xl p-4 ">
      <div class="relative">
      <div class="card top_primary_card bg-primary font-bold text-white p-4 rounded-xl absolute -top-11 w-full">
        Customer list ({{totalCustomerCount}})
      </div>
      </div>
      <div class="mt-8">
        <div class="flex flex-wrap justify-between items-center">
          <div class="w-72 mb-4">
            <SearchTextInput @searchCall="searchCall" :placeholderText="`Search Customer...`"/>
          </div>
          <div class="mb-4">
            <Button @buttonAction="addNewCust(false)" :btnSize="'large'" :textColor="'white'" :btnColor="'primary'" :btnText="'Add New'"/>
          </div>
        </div>
      </div>
      <div>
        <div style="overflow-y: auto; white-space: nowrap;" class="">
          <div class="text-gray4  min-w-min border-b border-gray1 font-bold flex heading-7  px-3 py-3 items-center">
            <div class="customer_name_col uppercase  flex items-center" :class="titleObj[0].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(0)">
              <span class="cursor-pointer">{{titleObj[0].title}}</span>
               <div v-if="titleObj[0].icon !== ''">
                  <descIconSvg v-if="titleObj[0].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="customer_name_col uppercase  flex items-center" :class="titleObj[1].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(1)">
              <span class="cursor-pointer">{{titleObj[1].title}}</span>
               <div v-if="titleObj[1].icon !== ''">
                  <descIconSvg v-if="titleObj[1].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="customer_name_col uppercase  flex items-center" :class="titleObj[2].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(2)">
              <span class="cursor-pointer">{{titleObj[2].title}}</span>
               <div v-if="titleObj[2].icon !== ''">
                  <descIconSvg v-if="titleObj[2].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
            <div class="customer_name_col uppercase  flex items-center" :class="titleObj[3].icon !== '' ? 'text-primary' : ''" @click="sortByColumn(3)">
              <span class="cursor-pointer">{{titleObj[3].title}}</span>
               <div v-if="titleObj[3].icon !== ''">
                  <descIconSvg v-if="titleObj[3].icon === 'DESC'" class="ml-2" :width="'20px'" :height="'20px'" />
                  <ascIconSvg v-else class="ml-2" :width="'20px'" :height="'20px'" />
                </div>
            </div>
          </div>
          <div v-if="customersList.length > 0">
            <div v-for="(data, index) in customersList" :key="index"  class="min-w-min flex heading-5 border-b border-gray1  bg-white  text-sm px-3 py-3 items-center hover:bg-gray1 cursor-pointer" @click="openCustomerDetails(data.customerId)">
              <div class="customer_name_col  ">
                <div class="">
                <!-- <p class="  text-text1 font-bold">{{}}</p> -->
                <p class="  text-text1 font-bold">{{data.companyName}}</p>
                <p class="  text-gray4 text">{{data.firstName}} {{data.lastName}}</p>
                </div>
              </div>
              <div class="customer_name_col  ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.emailAddress === '' ? '-' : data.emailAddress}}</span>
                </div>
              </div>
              <div class="customer_name_col">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.phoneNumber === '' ? '-' : data.phoneNumber}}</span>
                </div>
              </div>
              <div class="customer_name_col  ">
                <div class="flex items-center">
                <span class="  text-gray4 heading-6">{{data.addedDate | formatDataeTime}}</span>
                </div>
              </div>
            </div>
          </div>
          <div v-else>
            <NoRecords :alertMessage="`No records found.`" />
          </div>
          <div>
          <Pagination
                :totalDataCount="totalCustomerCount"
                :resetPagination="resetPaginationValues"
                @callApiAsPerPagination="getCustomerDataFromPagination"/>
        </div>
        </div>
      </div>
     </div>
     <AddCustomer v-if="addCustomer" />
   </div>
</template>
<script>
import MyJobApp from '@/api/MyJobApp.js'
import SearchTextInput from '@/View/components/searchTextInput.vue'
import Button from '@/View/components/globalButton.vue'
import ascIconSvg from '@/assets/images/svg/ascIconSvg.vue'
import deboucneMixin from '@/mixins/debounce.js'
import descIconSvg from '@/assets/images/svg/descIconSvg.vue'
import NoRecords from '@/View/components/noRecords.vue'
import Pagination from '@/View/components/pagination.vue'
import AddCustomer from './components/AddCustomer.vue'

export default {
  name: "customer",
  components: {
    AddCustomer,
    Pagination,
    NoRecords,
    descIconSvg,
    ascIconSvg,
    SearchTextInput,
    Button,
  },
  mixins: [deboucneMixin],
  data() {
    return {
      titleObj: [
        {title: 'Company Name', icon: '', sortName: 'companyName'},
        {title: 'Email', icon: '', sortName: 'emailAddress'},
        {title: 'Contact', icon: '', sortName: 'phoneNumber'},
        {title: 'Added Date', icon: 'DESC', sortName: 'addedDate'}
      ],
      customersList: [],
      searchForCustomer: '',
      getCustListDeboucne: null,
      resetPaginationValues: false,
      addCustomer: false,
      totalCustomerCount: 0,
      filterObject: {
        start: 0,
        length: 20,
        sortOrder: 'DESC',
        isActive: true,
        sortBy: 'addedDate'
      },
    };
  },
  created() {},
  mounted() {
    document.body.style = 'overflow: visible;'
    this.getCustomerList()
     this.getCustListDeboucne = this.debounce(function () {
      this.resetPaginationValues = true
    }, 500)
    this.$root.$on("addCustomerHandler", (response) => {
      console.log(response);
      if (response) {
        this.getCustomerList()
      }
      this.addCustomer = false
    });
  },
  beforeDestroy() {
    this.$root.$off('addCustomerHandler')
  },
  methods: {
    addNewCust () {
      this.addCustomer= true
    },
    getCustomerDataFromPagination (data) {
      this.filterObject.start = data.offset
      this.filterObject.length = data.limit
      
      this.getCustomerList(true)
    },
    sortByColumn (index) {
      console.log(index);
      for (let i = 0; i < this.titleObj.length; i++) {
        if (i === index) {
          if (this.titleObj[i].icon === '') {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          } else if (this.titleObj[i].icon === 'DESC') {
            this.titleObj[i].icon = 'ASC'
            this.filterObject.sortOrder = 'ASC'
          } else {
            this.titleObj[i].icon = 'DESC'
            this.filterObject.sortOrder = 'DESC'
          }
        } else {
          this.titleObj[i].icon = ''
        }
      }
      this.filterObject.sortBy = this.titleObj[index].sortName
      this.getCustomerList(true)
    },
    openCustomerDetails (id) {
      this.$router.push({name: 'customerDetails', params: {customerId: id}})
    },
    searchCall (data) {
      this.searchForCustomer = data
      this.getCustListDeboucne()
    },
    clickCall () {
      // this.$router.push({name: '/'})
      this.$router.push({name: 'invoice'})
    },
    getCustomerList (showlistLoader) {
      if (showlistLoader && this.searchForCustomer !== '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      if (this.searchForCustomer === '') {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      }
      MyJobApp.cuaromerList(
         this.filterObject.start,
          this.filterObject.length,
          this.searchForCustomer,
          this.filterObject.sortBy,
          this.filterObject.sortOrder,
          response => {
            this.totalCustomerCount = response.Data.count
            this.customersList = response.Data.tableRow !== null ? response.Data.tableRow : []
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          () => {
            this.resetPaginationValues = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          }
        )
    }
  }
};
</script>
<style scoped>
.customer_name_col {
  min-width: 300px !important;
  width: 300px !important;
  overflow-wrap: anywhere;
  white-space: pre-wrap;
  padding-right: 8px;
}
</style>